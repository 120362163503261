<template>
    <div class="lesson" v-if="lesson">
        <div class="lesson-banner row" :style="bannerBackgroundImage">
            <div class="banner-background" :style="backgroundColor"></div>
            <img class="info-icon" @click="showTeacherPage" :src="infoIcon"> 
            <img :src="image" class="lesson-image">
            <div class="column">
                <span class="lesson-title" v-tooltip="lesson.title" :style="{color: lesson.color, textShadow:' 2px 2px rgba(0,0,0,0.4)' }">{{lesson.title}}</span>
                <essential-question  v-if="essentialQuestionText" :key="lesson.essentialQuestion.id"
                    :audio="essentialQuestionAudio"    :message="essentialQuestionText" />
            </div>
        </div>
        <div class="lesson-background"  :style="backgroundColor"></div>
        <span v-if="paragraphs.length == 0" class="centered">{{$t('noContentToDisplayMessage')}}</span>
        <template v-else>
            <div class="lesson-container flex-container">
                <component v-for="paragraph in paragraphs" :is="paragraph.type"
                    :key="paragraph.id" :content="paragraph" />
            </div>
            <div class="controls-section flex-container">
                <button class="btn round primary" @click="redirectToNextContent">{{$t('next')}}</button>
            </div>
        </template>
    </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService.js"
import { Role } from '@/utils/Roles.js'
import LessonParagraph from '@/components/Lesson/LessonParagraph.vue'
import LessonTextContent from '@/components/Lesson/LessonTextContent.vue'
import VideoContent from '@/components/Lesson/VideoContent.vue'
import LessonCard from '@/components/Lesson/LessonCard.vue'
import SectionTitle from '@/components/Lesson/SectionTitle.vue'
import InteractiveMap from '@/components/Lesson/InteractiveMap.vue'
import EssentialQuestion from '@/common/EssentialQuestion.vue'
import OnlyImages from "@/components/Lesson/OnlyImages.vue";

export default {
  name: 'LessonLayout',
  components:{
      'paragraph':    LessonParagraph,
      'card':   LessonCard,
      'section-title': SectionTitle, 
      'text-content': LessonTextContent,
      InteractiveMap,
      VideoContent,
      EssentialQuestion,
      "only-images": OnlyImages
  },
  computed:{
      lesson(){
          return this.$store.state.currentLesson 
      },
      image(){
          return this.getImageUrl(this.lesson.imageName)
      },
      bannerBackgroundImage(){
          return {
              backgroundImage: `url(${this.getImageUrl(this.lesson.headerBackgroundName)})`
          } 
      },
      paragraphs(){
          return this.lesson.paragraphs
      },
      infoIcon(){
        return require('@/assets/icons/ic_info.svg')
      }, 
      backgroundColor(){
          return {
            backgroundColor:  this.lesson.background || '',
            opacity: this.lesson.opacity / 100 || 1
          }
      },
      essentialQuestionText(){
            if(!this.lesson || !this.lesson.essentialQuestion)
                return null
            let essentialQuestionText =  this.lesson.essentialQuestion.question
            return essentialQuestionText[this.locale].length > 0 
                ? essentialQuestionText
                : null
      },
      essentialQuestionAudio(){
            if(!this.lesson.essentialQuestion)
                return null
            return this.lesson.essentialQuestion.audio
      }
  }, 
  methods:{
    showTeacherPage(){
      if(this.$store.state.isPreviewModeActive)
        return 
       this.$router.push({ name: 'TeacherPageLesson', params: { 
           teacherPageId: this.lesson.skillsId,
           lessonId: this.lesson.id, 
           unitId: this.$route.params.unitId
           }, query:{
               title: this.lesson.title
           }
        })
    }, 
    goToAssessment(){
        this.$router.push({
            name: 'Assessment', 
            params: {
                ...this.$route.params
            }
        })
    }, 
    goToNextLesson(){
        let lessons = this.$store.state.currentUnit.lessons
        let nextLesson = lessons.find(e => e.number === this.lesson.number +1)
        if(!nextLesson){
            this.$router.push({ name: 'Unit', params: { unitId: this.$route.params.unitId }})
            return
        }
        this.setLessonCompleted()
        this.$router.push({
            name: 'Lesson', 
            params:{
                unitId: this.$route.params.unitId, 
                lessonId: nextLesson.id
            }
        })
    }, 
    redirectToNextContent(){
        if(this.$store.state.isPreviewModeActive)
            return 
        this.lesson.hasAssessment
            ? this.goToAssessment()
            : this.goToNextLesson()
    }, 
    setLessonCompleted(){
        let userRole = AuthenticationService.getProfile().roleName
        if(Role.Student.name === userRole){
            this.$store.dispatch('setLessonCompleted', this.lesson)   
        }
    }
  },
  created() {
      if(!this.lesson || this.lesson.id != this.$route.params.lessonId){
          this.isLoadingInformation = true
          this.$store.commit('loadLesson',null)
          this.$store.dispatch('loadLesson', this.$route.params.lessonId)
            .finally(()=>{
                this.isLoadingInformation = false
            });
      }
  },
    mounted(){
        this.startTrackingPage({ sectionName: "Lessons" });
        this.$store.commit("setAnalyzedManagementSection", "Lessons");
    },
  beforeDestroy(){
        this.setLessonCompleted()
        this.$store.commit("setAnalyzedManagementSection", "");
        this.stopTrackingPage({ sectionName: "Lessons" });
  }
}
</script>

<style lang="less" scoped>

.lesson{
    position: relative;
    justify-content: center;
    margin: 0 auto;
    z-index: 0;
    margin-top: 104px;
    height: 100vh;
    background-color: unset;

    .lesson-banner{
        width: 1280px;
        height: 250px;
        max-width: 1280px;
        max-height: 250px;
        margin: 0 auto;
        padding: 16px;
        box-sizing: border-box;
        position: relative;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .info-icon{
            margin-left: 24px;
            margin-top: 16px;
            margin-bottom: auto;
        }

        .lesson-image{
            height: auto;
            width: auto;
            margin-left: 30px;
            max-height: 244px;
            max-width: 468px;
        }
        .column{
            margin-top: 37px;
            margin-left: 32px;
        }
        .lesson-title{
            max-width: 820px;
            min-height: 41px;
            color: #3A4DFD;
            font-size: 33.64px;
            font-weight: 600;
            letter-spacing: 0.25px;
            line-height: 41px;
            white-space: normal;
            max-height: 120px;
        }
        .essential-question{
            margin-top: auto;
            max-height: 136px;
            padding: 8px;
            box-sizing: border-box;
            margin-right: auto;
            :global(span){
                height: auto;
                min-height: 16px;
                white-space: normal;
                max-height: 100%;
                width: auto;
                max-width: 505px;
                overflow-y: auto;
                font-size: 14px;
                line-height: 16px;

                &::-webkit-scrollbar {
                    width: 5px;
                }
                
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #f1f1f1; 
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: rgba(0,0,0,0.2); 
                    border-radius: 10px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555; 
                    background: rgba(0,0,0,0.2); 
                }
            }

            :global(.brain-image){
                height: 40px;
                width: 40px;
            }
        }
    }

    .lesson-container{ 
        margin: 0 auto;   
        flex-direction: column;
        justify-content: center;
        opacity: 1;
    }
    .btn{
        margin-left: auto;
        margin-top: 96px;
    }

    .lesson-background, .banner-background{
        z-index: -2;
        position: fixed;
        top: 0;
        min-height: 100%;
        width: 100%;
    }

    .banner-background{
        position: absolute;
        z-index: -1;
        width: 100%;
        max-height: 250px;
        height: 250px;
        left: 50%;
        transform: translate(-50%);
    }

    .controls-section{
        margin: 0 auto;   
        width: 1280px;
        padding-bottom: 60px;
    }

}

</style>