import { render, staticRenderFns } from "./LessonLayout.vue?vue&type=template&id=3d4fd6f3&scoped=true&"
import script from "./LessonLayout.vue?vue&type=script&lang=js&"
export * from "./LessonLayout.vue?vue&type=script&lang=js&"
import style0 from "./LessonLayout.vue?vue&type=style&index=0&id=3d4fd6f3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d4fd6f3",
  null
  
)

component.options.__file = "LessonLayout.vue"
export default component.exports